import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './assets/styles/App.css';
import './assets/styles/Navbar.css';
import SchemaMarkup, {
    homeSchema,
    contactSchema,
    solutionsSchema,
    aboutSchema,
    recruitmentSchema
} from './components/SchemaMarkup';

// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
Hero.preload = () => import('./components/Hero');
const Intro = lazy(() => import('./components/Intro'));
const SolutionsServices = lazy(() => import('./components/SolutionsServices'));
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/contact'));
const Recruitment = lazy(() => import('./components/Recruitment'));

const pageVariants = {
    initial: {
        opacity: 0,
        y: 20,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: -20,
    },
};

const footerVariants = {
    initial: {
        opacity: 0,
        y: 20,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: 20,
    },
};

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <Navbar />
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route
                        path="/"
                        element={
                            <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={pageVariants}
                                transition={{ duration: 0.5 }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SchemaMarkup schema={homeSchema} />
                                    <Hero />
                                    <Intro />
                                </Suspense>
                            </motion.div>
                        }
                    />
                    <Route
                        path="/solutions"
                        element={
                            <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={pageVariants}
                                transition={{ duration: 0.5 }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SchemaMarkup schema={solutionsSchema} />
                                    <SolutionsServices />
                                </Suspense>
                            </motion.div>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={pageVariants}
                                transition={{ duration: 0.5 }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SchemaMarkup schema={aboutSchema} />
                                    <About />
                                </Suspense>
                            </motion.div>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={pageVariants}
                                transition={{ duration: 0.5 }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SchemaMarkup schema={contactSchema} />
                                    <Contact />
                                </Suspense>
                            </motion.div>
                        }
                    />
                    <Route
                        path="/recruitment"
                        element={
                            <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={pageVariants}
                                transition={{ duration: 0.5 }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SchemaMarkup schema={recruitmentSchema} />
                                    <Recruitment />
                                </Suspense>
                            </motion.div>
                        }
                    />
                </Routes>
            </AnimatePresence>
            <AnimatePresence mode="wait">
                <motion.div
                    key={location.pathname}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={footerVariants}
                    transition={{ duration: 0.5 }}
                >
                    <Footer />
                </motion.div>
            </AnimatePresence>
        </div>
    );
}

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
