import { useEffect } from 'react';
import PropTypes from 'prop-types';

const SchemaMarkup = ({ schema }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(schema);
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, [schema]);

    return null;
};

SchemaMarkup.propTypes = {
    schema: PropTypes.object.isRequired,
};

// Home page schema
const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Apex Digital.One",
    "alternateName": "Apex Digital",
    "url": "https://apexdigital.one",
    "logo": "https://apexdigital.one/SElogo.png",
    "description": "Join Apex Digital.one, digital business agency with experienced team and track record to help " +
        "global businesses raising to new levels with their digital channel business.",
};

// Solutions/Services page schema
const solutionsSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Apex Digital.One",
    "description": "Apex Digital.One provides comprehensive digital transformation solutions and services for businesses across various sectors, focusing on BtoC, BtoB, and D2C journeys.",
    "url": "https://apexdigital.one/solutions",
    "logo": "https://apexdigital.one/SElogo.png",
};

// About page schema
const aboutSchema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "About Us",
    "description": "Learn about Apex Digital.One, our mission, and our expertise in Digital Commerce and Digital Business.",
    "url": "https://apexdigital.one/about",
    "logo": "https://apexdigital.one/SElogo.png",
    "mainEntity": {
        "@type": "Organization",
        "name": "Apex Digital.One",
        "url": "https://apexdigital.one",
        "logo": "https://apexdigital.one/SElogo.png",
        "description": "Apex Digital.One, established in 2024, specializes in Digital Commerce and Digital Business solutions. We are assembling a globally recognized team of experts to provide cutting-edge digital transformation services.",
        "foundingDate": "2024",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+358 50 374 8352",
            "contactType": "customer service",
            "areaServed": "FI",
            "availableLanguage": ["English", "Finnish"]
        },
    }
};

// Contact page schema
const contactSchema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Us",
    "description": "Get in touch with our team",
    "url": "https://www.apexdigital.one/contact",
    "logo": "https://apexdigital.one/SElogo.png",
    "mainEntity": {
        "@type": "Organization",
        "name": "Apex Digital.One",
        "logo": "https://apexdigital.one/SElogo.png",
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+358 50 374 8352",
                "contactType": "customer service",
                "areaServed": "FI",
                "availableLanguage": ["English", "Finnish"]
            }
        ]
    }
};

// Recruitment page schema
const recruitmentSchema = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Join Our Team",
    "description": "Explore career opportunities at our company",
    "url": "https://www.apexdigital.one/recruitment",
    "mainEntity": {
        "@type": "Organization",
        "name": "Apex Digital.One",
        "url": "https://www.apexdigital.one",
        "logo": "https://www.apexdigital.one/SElogo.png",
    },
    "jobPostings": [
        {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "PIM Developer or Architect",
            "description": "At Apex Digital, we believe in the power of top-notch PIM systems. Due to our rapid growth, we are looking for experienced developers and architects to join our expanding PIM team. Your duties would include designing and implementing data models, data management processes, data quality controls, and looking into onboarding and releasing the data using different integration methods.",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "Apex Digital.One",
                "sameAs": "https://www.apexdigital.one"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "FI",
                    "addressLocality": "Remote"
                }
            },
            "employmentType": "Full-time",
            "datePosted": "2024-07-01",
            "responsibilities": "Designing and implementing data models and data management processes, collaborating with customers to create solutions that drive business success.",
            "skills": [
                "PIM systems",
                "APIs",
                "Understanding enterprise architecture",
                ".NET",
                "Azure",
                "AWS",
                "C#",
                "PHP",
                "Java",
            ],
            "qualifications": "Several years of experience in PIM platforms, software development, or implementing integration solutions.",
            "howToApply": "Send your application along with your salary expectations to recruitment@apexdigital.one. We review applications as we receive them."
        },
        {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "eCommerce Architect / Senior Developer",
            "description": "As eCommerce Architect you are the interpreter between business requirements and technical solutions. You will work in versatile environments giving direction to implementation teams, ours or customers’. You identify the customer need, mirror that to the system architecture capabilities, form requirements and find the best way to implement them with your team.",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "Apex Digital.One",
                "sameAs": "https://www.apexdigital.one"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "FI",
                    "addressLocality": "Remote"
                }
            },
            "employmentType": "Full-time",
            "datePosted": "2024-07-01",
            "responsibilities": "Design solution architecture, Identify master data, system roles, what should be done where, design data flows and processes related to the digital business domain, Lead technical teams to good results",
            "skills": [
                "Strong knowledge of the role of different domain systems like ECOM, PIM, OMS, CMS, MA etc",
                "Experience in the Architect role and proven capability to design eCommerce architectures",
                "Technical prowess, understanding of programming beyond languages",
                "Knowledge of extending commercial software platforms, ability to design & implement also solutions based on microservices to flexibly extend the system architecture capabilities",
            ],
            "qualifications": "Several years of experience in eCommerce implementation projects, experience in Architect role.",
            "howToApply": "Send your application along with your salary expectations to recruitment@apexdigital.one. We review applications as we receive them."
        },
        {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "Senior Digitalization Advisor",
            "description": "As Senior Digitalization Advisor, you are APEX’ face as a digitalization agent and trusted business partner to our customers.",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "Apex Digital.One",
                "sameAs": "https://www.apexdigital.one"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "FI",
                    "addressLocality": "Remote"
                }
            },
            "employmentType": "Full-time",
            "datePosted": "2024-07-01",
            "responsibilities": "You maintain a business-first approach in digital transformation through direct dialogue with the business leaders on our customers side.",
            "skills": [
                "You have experience with various business models including B2C, B2B2X, D2C, Marketplaces",
                "excellent level of English both written and spoken",
                "You can act yourself as project manager for smaller initiatives",
                "Are familiar with the technology scene, including the top tiers in Gartner's magic quadrants"
            ],
            "howToApply": "Send your application along with your salary expectations to recruitment@apexdigital.one. We review applications as we receive them."
        },
        {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "High-End Digital Business Expert",
            "description": "We are looking for a high-end digital business expert who can join our team and help us deliver the best solutions and services to our customers.",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "Apex Digital.One",
                "sameAs": "https://www.apexdigital.one"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "FI",
                    "addressLocality": "Remote"
                }
            },
            "employmentType": "Full-time",
            "datePosted": "2024-07-01",
            "responsibilities": "Designing digital strategy and roadmap, leading digital projects, collaborating with technical teams and vendors, providing thought leadership.",
            "skills": [
                "Project management",
                "APIs",
                "Understanding enterprise architecture",
                ".NET",
                "Azure",
                "AWS",
                "C#",
                "PHP",
                "Java",
                "React"
            ],
            "howToApply": "Send your application along with your salary expectations to recruitment@apexdigital.one. We review applications as we receive them."
        }
    ]
};

export default SchemaMarkup;
export { homeSchema, solutionsSchema, aboutSchema, contactSchema, recruitmentSchema };