import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-links">
                <Link to="/">Home</Link>
                <Link to="/solutions">Solutions & Services</Link>
                <Link to="/about">About Us</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/recruitment">Recruitment</Link>
            </div>
            <div className="heightD">
                <div className="bold">Helsinki</div>
                <p>Malmin kauppatie 18<br/>Malmintorin kauppakeskus, 4. krs <br/> 00700 Helsinki <br/></p>
            </div>
                <p>Business ID: 2505713-4</p>
        </div>
);
};

export default Footer;
