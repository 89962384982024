import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleBurgerClick = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <nav className="navbar">
            <Link to="/" className="logo"></Link>
            <ul className={`nav-links ${isOpen ? 'nav-active' : ''}`}>
                <li><Link to="/solutions" onClick={handleLinkClick}>Solutions & Services</Link></li>
                <li><Link to="/about" onClick={handleLinkClick}>About us</Link></li>
                <li><Link to="/contact" onClick={handleLinkClick}>Contact us</Link></li>
                <li><Link to="/recruitment" onClick={handleLinkClick}>Recruitment</Link></li>
            </ul>
            <div className={`burger ${isOpen ? 'toggle' : ''}`} onClick={handleBurgerClick}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
        </nav>
    );
};

export default Navbar;
